
import { Component, Vue, Watch } from "vue-property-decorator";
import TransitionFade from "../components/TransitionFade.vue";

export interface ContactFormData {
  [key: string]: string;
}

@Component({
  name: "ContactForm",
  components: {
    TransitionFade
  }
})
export default class ContactForm extends Vue {
  public phoneRegex = /^[+]{0,1}[0-9]{9,13}$/;
  public emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public nameRegex = /^[a-zA-Z0-9 _\-.]{3,50}$/;
  public messageRegex = /^[a-zA-Z0-9 _\-.,;?!]{10,300}$/;

  public x: number | undefined;
  public y: number | undefined;
  public operator: string | undefined;

  public isFormLoading = false;
  public validation = {
    form: false,
    check: false
  };

  public contactFormData: ContactFormData = {
    name: "",
    message: "",
    phone: "",
    email: "",
    antispam: ""
  };

  public securityCheck = "";
  public securityPlaceholder = "";

  public contactFormErrors: ContactFormData = {
    name: "",
    message: "",
    phone: "",
    email: "",
    antispam: "",
    check: ""
  };

  public formError = "";
  public formSuccess = false;

  @Watch("contactFormData", { deep: true })
  onContactFormDataChanged(newValue: ContactFormData) {
    const isFormValid = this.checkFormValidation(newValue);
    if (!isFormValid) {
      this.validation.form = false;
      this.validation.check = false;
      this.securityCheck = "";
      return;
    }

    if (!this.validation.form) {
      this.generateNewSecurityQuestion();
      this.securityPlaceholder = `Care este rezultatul pentru ${this.x} ${this.operator} ${this.y} ?`;
      this.validation.form = true;
    }
  }

  @Watch("securityCheck")
  onSecurityCheckChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.checkSecurityValidation(newValue);
    }
  }

  private generateNewSecurityQuestion() {
    this.x = Math.floor(Math.random() * 7);
    this.y = Math.floor(Math.random() * 7);

    if (this.x < this.y) {
      const aux = this.x;
      this.x = this.y;
      this.y = aux;
    }

    this.operator = Math.floor(Math.random() * 2) === 0 ? "+" : "-";
  }

  private checkFormValidation(formData: ContactFormData) {
    if (formData.antispam) {
      return false;
    }

    const isNameValid = this.nameRegex.test(formData.name);
    const isEmailValid = this.emailRegex.test(formData.email);
    const isPhoneValid = this.phoneRegex.test(formData.phone);
    const isMessageValid = this.messageRegex.test(formData.message);

    if (formData.name && !isNameValid) {
      this.setError(
        "name",
        "Numele trebuie sa contina intre 3 si 50 caractere (doar litere, cifre si semne de punctuatie)"
      );
    } else {
      this.setError("name", "");
    }

    if (formData.phone && !isPhoneValid) {
      this.setError("phone", "Numarul de telefon trebuie sa fie valid");
    } else {
      this.setError("phone", "");
    }

    if (formData.email && !isEmailValid) {
      this.setError("email", "Adresa de email trebuie sa fie una valida");
    } else {
      this.setError("email", "");
    }

    if (formData.message && !isMessageValid) {
      this.setError(
        "message",
        "Mesajul trebuie sa contina intre 10 si 300 caractere (doar litere, cifre si semne de punctuatie)"
      );
    } else {
      this.setError("message", "");
    }

    if (isNameValid && isEmailValid && isPhoneValid && isMessageValid) {
      return true;
    }

    return false;
  }

  private checkSecurityValidation(securityCheck: string) {
    if (this.x === undefined || this.y === undefined) {
      return;
    }

    let isFormSecure = false;
    if (this.operator === "+") {
      isFormSecure = securityCheck === (this.x + this.y).toString();
    } else {
      isFormSecure = securityCheck === (this.x - this.y).toString();
    }

    if (!securityCheck) {
      this.setError("check", "");
      this.validation.check = false;
      return;
    }

    if (!isFormSecure) {
      this.setError("check", "Rezultatul introdus este gresit");
      this.validation.check = false;
      return;
    }

    this.setError("check", "");
    this.isFormLoading = true;
    const formCheckElement = document.getElementById("form-check");
    if (formCheckElement) {
      formCheckElement.setAttribute("readonly", "true");
    }
    setTimeout(() => {
      this.isFormLoading = false;
      this.validation.check = true;
    }, 2000);
  }

  public setError(contactField: string, error: string) {
    for (const key of Object.keys(this.contactFormErrors)) {
      if (key === contactField) {
        this.contactFormErrors[key] = error;
      }
    }
  }

  public submitForm() {
    if (!this.validation.check || !this.validation.form) {
      return;
    }

    const lastSubmission = localStorage.getItem("vue-pageclip-contact-form");
    if (
      lastSubmission &&
      new Date().getTime() - parseInt(lastSubmission, 10) < 300000
    ) {
      this.formError =
        "Ai trimis deja un mesaj. Te rugam sa astepti pana vei putea trimite din nou.";
      return;
    }

    (window as any).Pageclip.send(
      "Ymq0BSx3bF7de86VaSvvcKJniQTm64ZY",
      "",
      this.contactFormData,
      this.setEmailDelay.bind(this)
    );
  }

  private setEmailDelay() {
    this.formSuccess = true;

    setTimeout(() => {
      this.formSuccess = false;
    }, 5000);

    this.contactFormData = {
      name: "",
      message: "",
      phone: "",
      email: "",
      antispam: ""
    };

    this.securityCheck = "";
    this.securityPlaceholder = "";

    this.contactFormErrors = {
      name: "",
      message: "",
      phone: "",
      email: "",
      antispam: "",
      check: ""
    };

    this.formError = "";
    const currentTime = new Date().getTime().toString();
    localStorage.setItem("vue-pageclip-contact-form", currentTime);
  }
}
