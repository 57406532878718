
import Footer from '@/components/Footer.vue';
import { Component, Vue } from 'vue-property-decorator';
import ContactForm from '../components/ContactForm.vue';

@Component({
    name: 'Contact',
    components: {
        Footer,
        ContactForm,
    },
})
export default class Contact extends Vue {}
